import { useEffect, useState } from 'react'
import React from "react";
import {
  Routes, Route, useParams, BrowserRouter as Router,
} from "react-router-dom";

import './App.css';

function App() {

  return (
    <Router>
      <Routes>

        <Route path="/:username" element={<Profile />} />


      </Routes>
    </Router>
  )


}

function Profile() {

  let params = useParams();


  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`https://apiv2.pindrop.it/publicProfile?profile=${params.username}`).then(res => res.json()).then(d => {
      console.log(d);
      setData(d);
    });
  }, [params.username]);


if (data) {
  return (
      <div class="outerContainer" style={{ backgroundImage: `url(https://pindropbackup.blob.core.windows.net/img/${data.headerPhoto})` }}>

        <div class="container" >
          <div class="modal">
            <div>
              <div class={"centered"} >

                <img src={`https://pindropbackup.blob.core.windows.net/img/${data.profilePhoto}`}
                  style={{ width: 100, height: 100, borderRadius: 50, objectFit: "cover", display: "block", marginLeft: "auto", marginRight: "auto" }} />

              </div>
              <p style={{ color: "#3A3E44", textAlign: "center", fontSize: 24, fontWeight: "bold", paddingTop: 16 }}>{data.name}</p>
              <p style={{ color: "#3A3E44", textAlign: "center", fontSize: 24, paddingTop: 4 }}>@{data.username}</p>
              <p style={{ color: "#3A3E44", textAlign: "center", fontSize: 12, fontWeight: "700", paddingTop: 8 }}>pindrop.me/{data.username}</p>

              <p style={{ marginTop: 24, marginBottom: 32, width: 300, textAlign: "center", fontSize: 16, color: "white", backgroundColor: "#54AD95", fontSize: 17, fontWeight: "700", padding: 13, borderRadius: 8 }}>
                Follow on Pin Drop
              </p>



              <a href="https://apps.apple.com/us/app/pin-drop-custom-private-maps/id425356789">
                <img src="/images/AppStore.png" width="120" style={{ padding: 16 }} />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.pindropapp">
                <img src="/images/GooglePlay.png" style={{ padding: 16 }} width="120" />
              </a>


            </div>
          </div>
        </div>
    </div>
  
  );
} else {
  return (
    <div>Loading</div>
  )
}

}

export default App;
